.App {
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #EFEFEF;
}

.main {
  font-family: 'HelveticaNeue-Bold';
  width: 100%;
  height: 100%;
  background-color: #EFEFEF;
  background-size: cover;
  /* background-image: url("https://dimenschen-profile-images.s3.us-west-2.amazonaws.com/D_App_Splash.gif"); */
  /* background-size: contain; */
  /* background-position:center; */
  /* background-repeat: repeat; */
  /* padding-bottom: 100em; */
}

textarea:focus::placeholder {
  color: transparent;
}

.topnav {
  color: #ffffff;
  /* text-align: center; */
  padding: 1em;
  text-decoration: none;
  font-size: 1.5em;
}

.body {
  color: #ffffff;
  text-align: center;
  padding: 1em;
  text-decoration: none;
  font-size: 1.5em;
}



.loading {
  margin-top: 2em;
  color: #000033;
  font-size: 1;
  font-weight: 300;
}


/* not in use */





.description {
  padding-left: 2em;
  padding-right: 2em;
  /* padding-bottom: 2em; */
  text-align: center;
  font-size: 1.2em;
}


.flex-container {
  display: flex;
  background-color: #000033;
  padding: 1em;
}

.flex-container-assign-props {
  margin-top: 5em;
  display: flex;
  background-color: #000033;
  padding: 1em;
}

.assign-props-child {
  flex: 1;
  padding: 2em;
}

select {
  width: 30%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.icon { 
  height: 6em;
  text-align: left;
}

.headerRow {
  padding-top: 2em;
  padding-bottom: 2em;
}

.row {
  padding-top: 1em;
}

.title {
  text-align: center;
  padding-top: 5em;
}



.action-item {
  /* text-align: left; */
  font-size: 0.9em;
  color: #20d1cc;
}

.image {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-bottom: 4em;
}


.footerFlexButtonContainer {
  text-align: center; 
  display: flex;
  /* background-color: #000033; */
  padding: 1em;
  margin-top: 5em;
}



.disable-button {
  text-align: center;
  font-size: 1em;
  padding: 0.6em;
  margin-top: 0.5em;
  margin-bottom: 2em;
  width: 12em;
  cursor: not-allowed;
  background-color: #20d1cc;
  color: #ffffff;
  overflow: hidden;
  border: 1px solid #ffffff;
}

.wallet-address {
  cursor: pointer;
  padding: 1em;
}
