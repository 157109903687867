.row {
  padding-top: 1em;
}

.headerContainer {
  text-align: left; 
  padding-top: 2em;
  /* background-color: #000033; */
}

.headers {
  text-align: left;
}

.descriptionBody {
  font-family: 'HelveticaNeue-Light';
  color: black;
  font-weight: 600;
  text-align: left;
}

.warning {
  font-family: 'HelveticaNeue-Light';
  color: red;
  font-weight: 600;
  text-align: left;
}

.instructions {
  text-align: left;
  font-size: 0.95em;
  color: black;
}

.type {
  text-align: right;
  font-weight: 500;
}

.dimes {
  text-align: left;
  font-weight: 700;
}

.button {
  text-align: center;
  font-size: 0.5em;
  padding: 0.1em;
  /* margin-top: 0.5em; */
  /* margin-bottom: 2em; */
  /* width: 10em; */
  cursor: pointer;
  background-color: #20d1cc;
  /* color: #ffffff; */
  color: white;
  overflow: hidden;
  border: 1px solid #ffffff;
}

.headerContainer {
  text-align: center; 
  padding-top: 2em;
  /* background-color: #000033; */
}

.flex-child {
  flex: 1;
  padding-right: 3em;
  padding-left: 3em;
  /* border: 2px solid yellow; */
} 