
.container {
  /* display: flex; */
  background-color: #000033;
  padding: 10px;
  /* border: 0.05em solid #ffffff; */
  border-radius: 0.25em;
  /* outline-width: 0.5em; */
  max-width: 60%;
}

.username {
  color: #ffffff;
  text-align: left;
  padding-left: 0.2em;
  padding-top: 0.8em;
  text-decoration: none;
  font-size: 0.7em;
  font-weight: 300;
  cursor: pointer;
}

.text {
  color: #ffffff;
  text-align: center;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 0.8em;
  padding-bottom: 1em;
  text-decoration: none;
  font-size: 0.8em;
}

.id {
  color: #ffffff;
  float: right;
  /* padding: 1em; */
  text-decoration: none;
  font-size: 0.8em;
}

.value {
  color: #ffffff;
  text-align: center;
  /* padding: 1em; */
  text-decoration: none;
  font-size: 1em;
}

.subtext {
  color: #ffffff;
  text-align: center;
  /* padding: 1em; */
  text-decoration: none;
  font-size: 0.6em;
}

.textarea {
  margin-top: 1em;
  width: 90%;
  text-align: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #000033;
  resize: none;
  color: white;
  font-size: .8em;
}

::placeholder {
  font-size: .8em;
  font-weight: 100;
  color: white;
}


/* .button {
  text-align: center;
  font-size: 1em;
  padding: 0.6em;
  margin-top: 0.5em;
  margin-bottom: 2em;
  width: 12em;
  cursor: pointer;
  background-color: #20d1cc;
  color: #ffffff;
  overflow: hidden;
  border: 1px solid #ffffff;
} */